import Button from "common/Button";
import Input from "common/Input/Input";
import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";

import { v4 as uuidv4 } from "uuid";
import { Link, useHistory } from "react-router-dom";
// css
import "./index.scss";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { errorReset, signupUser, userSelector } from "./userSlice";
import { yupResolver } from "@hookform/resolvers/yup";
const RegisterForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const history = useHistory();
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const schema = Yup.object().shape({
    firstName: Yup.string().required("First name is required."),
    lastName: Yup.string().required("Last name is required."),
    email: Yup.string().email("Invalid email").required("Email is required."),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone Number is required."),
    password: Yup.string()
      .min(8, "Must be 8 characters or more")
      .max(32, "Must not be more than 32 characters")
      .required("Password is required."),
    passwordConfirmation: Yup.string()
      .min(8, "Must be 8 characters or more")
      .max(32, "Must not be more than 32 characters")
      .required("Password is required.")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const password = useRef({});
  password.current = watch("password", "");

  const dispatch = useDispatch();

  const { userRegister, userData, isFetching, isError, errorMessage } =
    useSelector(userSelector);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      document.getElementById("register-button").click();
    }
  };
  useEffect(() => {
    if (userData && userData.access_token) {
      history.push("/");
    }
    dispatch(errorReset());
  }, [history, dispatch, userData]);

  useEffect(() => {
    if (userRegister?.status === 200) {
      history.push("/verify");
    }
  }, [history, userRegister]);

  // useEffect(() => {
  //   if (userRegister && userRegister.status === 200) {
  //     dispatch(unshowRegister());
  //     dispatch(showVerify());
  //     history.push('/verify');
  //   }
  // }, [dispatch, history, userRegister]);

  // const openLogin = () => {
  //   dispatch(showLogin());
  //   dispatch(unshowRegister());
  // };

  const onSubmit = async (data) => {
    const { email, password, firstName, lastName, phone } = data;
    await dispatch(
      signupUser({
        email,
        password,
        profile: {
          first_name: firstName,
          last_name: lastName,
          phone,
          image_uri: "",
          device_identifier: uuidv4(),
          login_type: "custom",
          device_type: "web",
        },
      })
    );
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleRepeatPassword = () => {
    setShowRepeatPassword(!showRepeatPassword);
  };

  return (
    <div className="registerform">
      <h1>Sign Up a new account </h1>

      <form
        className="registerform__form"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* <div className="registerform__form--flex">
          <Input
            name="firstName"
            type="text"
            size="md"
            placeholder="First Name"
            errors={errors?.firstName}
            control={control}
          />
          <Input
            name="lastName"
            type="text"
            size="md"
            placeholder="Last name"
            errors={errors?.lastName}
            control={control}
          />
        </div> */}

        <Input
            name="firstName"
            type="text"
            size="md"
            placeholder="First Name"
            errors={errors?.firstName}
            control={control}
          />
          <Input
            name="lastName"
            type="text"
            size="md"
            placeholder="Last name"
            errors={errors?.lastName}
            control={control}
          />
        <Input
          name="email"
          type="email"
          size="md"
          placeholder="Email"
          control={control}
          errors={errors?.email}
        />
        <Input
          name="phone"
          type="number"
          size="md"
          placeholder="Phone number"
          control={control}
          errors={errors?.phone}
        />
        <Input
          name="password"
          type="password"
          size="md"
          placeholder="Password"
          control={control}
          errors={errors?.password}
        />
        <Input
          name="passwordConfirmation"
          type="password"
          size="md"
          placeholder="repeat Password"
          control={control}
          errors={errors?.passwordConfirmation}
        />

        <Button
          style={{ width: "100%" }}
          variant="contained"
          size="md"
          color="gradient"
          type="submit"
          isLoading={isFetching}
          // onClick={() => history.push("/verify")}
        >
          sign up
        </Button>
      </form>
      <p>
        By signing up, you agree to our<span> Terms of Use</span> and
        <span> Privacy Policy.</span>
      </p>
      <h2>
        Already have an account?{" "}
        <Link to="/login">
          <span>Log In</span>
        </Link>
      </h2>
    </div>
  );
};

export default RegisterForm;
